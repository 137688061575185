import * as React from 'react';
import Layout from "../components/layout";
import getGlobalContent from "../lib/getAllContent";
import {MDBBtn, MDBCol, MDBContainer, MDBRow} from "mdb-react-ui-kit";
import {Link} from "gatsby";

const NotFoundPage = () => {
    const globalData = getGlobalContent();
    const texts = globalData.file.childMdx.frontmatter;

    return (
        <Layout pageTitle="Home page">
          <section id="404">
            <MDBContainer className="centered-section py-0 py-md-5">

              <MDBRow className="text-center py-5">
                <MDBCol size="12" md="12" className="content-col py-5">
                  <h1>{texts.notFoundHeadline}</h1>
                  <p className="py-4">{texts.notFoundText}</p>
                  <Link to={texts.notFoundLink}><MDBBtn size="lg">{texts.notFoundButton}</MDBBtn></Link>
                </MDBCol>
              </MDBRow>

            </MDBContainer>
          </section>
        </Layout>
    )
}

export default NotFoundPage